import React, { useContext, useEffect } from 'react';
import AppContext from "@src/context"
// Structure imports
import Seo from '@components/utility/SEO';
import SignupForm from '@components/content/signup/SignupForm';
import { isMobile } from '@klickinc/kf-react-components';
// style imports
import './styles.scss';

const SignUp = () => {
	const ctx = useContext(AppContext);

	const runScreenshots = () => {
		var url = window.location.href;

		if (url.includes('ssFirstNameInput')) {
			document.getElementById('first_name').value = 'abc';
		}

		if (url.includes('ssLastNameInput')) {
			document.getElementById('last_name').value = '123';
		}

		if (url.includes('ssEmailAddressInput')) {
			document.getElementById('email').value = 'abc@klick.com';
		}

		if (url.includes('ssZipCodeInput')) {
			document.getElementById('zip').value = '90011';
		}
		if (url.includes('ssYearBirthInput')) {
			document.getElementById('childBirthYear').value = '2000';
		}
		if (url.includes('ssTermsConditionInput')) {
			document.getElementById('consent').checked = true;
		}
		if (url.includes('ssTermsConditionModalOpen')) {
			ctx.setModalOpen(true);
			ctx.setModalType('content');

			if (!isMobile()) {
				document.querySelector("html").classList.add('overFlow');
			} else {
				const modalOverlay = document.querySelector(".modal > .modal__overlay");
				modalOverlay.style.height = document.querySelector("html").offsetHeight + 'px';
				const modalBody = document.querySelector(".modal .modal__body");
				const modalBodyHeight = document.querySelector(".modal .modal__body > div").offsetHeight + 100 + 'px';
				modalBody.style.height = modalBodyHeight;
				modalBody.classList.add('ssTermsConditionModalOpen');
			}
		}
		if (url.includes('ssSignupError')) {
			document.getElementById('first_name').value = '';
			document.getElementById('last_name').value = '';
			document.getElementById('email').value = '';
			document.getElementById('zip').value = '';
			document.getElementById('childBirthYear').value = '';
			document.getElementById('consent').checked = false;

			const formFields = document.querySelectorAll('form .field');
			if (formFields) {
				formFields.forEach((field) => {
					field.classList.add('error');
				});
			}


			const dropdown = document.getElementById('childBirthYear')
			if (dropdown.parentNode.parentNode.classList.contains('error')) {
				dropdown.selectedIndex = 0;
			}

		}
	};

	useEffect(() => {
		ctx.setPageClass('page--sign-up-for-updates');

		if (window.location.href.indexOf('ed=1') > -1) {
			setTimeout(() => {
				runScreenshots();
			}, 1000);
		}
	}, []);

	return (
		<>
			<div className='page--sign-up'>
				<section className='relative'>
					<div className='container'>
						<h1 className="heading heading-hero-blue text-[30px] xs:w-[295px] xs:leading-7 md:w-[715px] md:text-6xl md:leading-[70px]">sign up for updates & support</h1>
						<p className='orange_subHeader font-gotham'>Tell us about yourself</p>
						<p className='pb-8'>All fields are required.</p>
						<SignupForm />
					</div>
					<div className="signup submitSection">
					</div>
				</section>
			</div>
		</>
	);
};

export default SignUp;

export function Head() {
	return (
		<Seo
			title='Sign Up For Updates - EMFLAZA® (deflazacort)'
			description='Sign up for updates and support for EMFLAZA® (deflazacort). See full Prescribing Information & Important Safety Information.'
			addSocialMeta={false}
		/>
	);
};
